export const ActionTypes = {

	//Auth
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_FAILURE: 'LOGIN_FAILURE',
	LOGOUT_REQUEST: 'LOGOUT_REQUEST',


	//Users
	USER_PROFILE: 'USER_PROFILE',


	//Forum
	LIST_FORUMS_REQUEST: 'LIST_FORUMS_REQUEST',
	LIST_FORUMS_SUCCESS: 'LIST_FORUMS_SUCCESS',
	FORUM_REQUEST: 'FORUM_REQUEST',
	FORUM_REQUEST_SUCCESS: 'FORUM_REQUEST_SUCCESS',
	CREATE_FORUM_REQUEST: 'CREATE_FORUM_REQUEST',
	CREATE_FORUM_SUCCESS: 'CREATE_FORUM_SUCCESS',
	CREATE_FORUM_FAILURE: 'CREATE_FORUM_FAILURE',

	DELETE_FORUM_REQUEST: 'DELETE_FORUM_REQUEST',
	DELETE_FORUM_SUCCESS: 'DELETE_FORUM_SUCCESS',
	DELETE_FORUM_FAIL: 'DELETE_FORUM_FAIL',

	CREATE_THREAD_REQUEST: 'CREATE_THREAD_REQUEST',
	CREATE_THREAD_SUCCESS: 'CREATE_THREAD_SUCCESS',
	THREAD_REQUEST: 'THREAD_REQUEST',
	THREAD_REQUEST_SUCCESS: 'THREAD_REQUEST_SUCCESS',
	CREATE_THREAD_POST_REQUEST: 'CREATE_THREAD_POST_REQUEST',
	CREATE_THREAD_POST_SUCCESS: 'CREATE_THREAD_POST_SUCCESS',

	DELETE_THREAD_REQUEST: 'DELETE_THREAD_REQUEST',
	DELETE_THREAD_SUCCESS: 'DELETE_THREAD_SUCCESS',
	DELETE_THREAD_FAIL: 'DELETE_THREAD_FAIL',

}