import { ActionTypes } from "../Constants/action-types";


const initialState = {
	isLoading: false,
	data: '',
	create: ''
}

export const createForumReducer = (state=initialState,action) => {

	switch(action.type){
		case ActionTypes.CREATE_FORUM_REQUEST:
			return {
				...state,
				isLoading: true,
			}
		case ActionTypes.CREATE_FORUM_SUCCESS:
			return {
				...state,
				isLoading: false,
				data: action.payload,
				create: 'Success'
			}
		case ActionTypes.CREATE_FORUM_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.error
			}
		default: 
			return state;
	}
}