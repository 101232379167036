export const baseUrlProd1 = 'https://usnepalese.com';
export const baseUrlDev1 = 'https://usnepalese.com';
export const baseUrl1 = process.env.NODE_ENV === 'development' ? baseUrlDev1 : baseUrlProd1;

export const baseUrlProd = 'https://usnepalese.com/api/';
export const baseUrlDev = 'https://usnepalese.com/api/';
export const baseUrl = process.env.NODE_ENV === 'development' ? baseUrlDev : baseUrlProd;

//Auth
export const USER_LOGIN_URL = baseUrl + 'login/';
export const USER_PROFILE_URL = baseUrl + 'users/profile/';


//Forum
export const LIST_FORUMS_URL = baseUrl + 'forum/';
export const CREATE_FORUM_URL = baseUrl + 'forum/create/';
//export const DELETE_FORUM_URL = baseUrl + 'forum/${Id}/delete/';

export const THREAD_URL = baseUrl + 'thread/';
export const CREATE_THREAD_URL = baseUrl + 'thread/create/';
export const CREATE_POST_URL = baseUrl + 'post/create/';