import React, { createContext, useState, useEffect } from "react";
import axios from 'axios';
import { baseUrl } from '../Api/constants';
export const AuthContext = createContext();

const AuthContextProvider = (props) => {

    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('usb__jwttoken') ? true : false);
    const [userProfile, setUserProfile] = useState([]);
    const [isDataManipulated, setIsDataManipulated ] = useState(true);

    useEffect(() => {
        const fetchedData = async() => {
            if(isLoggedIn === true){
                const token = localStorage.getItem("usb__jwttoken");
                const axiosConfig = {
                    headers: {
                      Authorization: `Token ${token}`,
                    },
                };
                const results = await axios.get(`${baseUrl}users/profile/`, axiosConfig);   
                setUserProfile(results.data);
            };
            setIsDataManipulated(false);
        }
        if(isDataManipulated){
            fetchedData();
        }
    },[isLoggedIn,isDataManipulated])

    return (
        <AuthContext.Provider value={{ isLoggedIn,setIsLoggedIn,userProfile,isDataManipulated, setIsDataManipulated }}>
            {props.children}
        </AuthContext.Provider>
    )

}

 
export default AuthContextProvider;