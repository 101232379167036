import { ActionTypes } from "../Constants/action-types";


const forumsInitialState = {
	isLoading: false,
	data: []
}

const forumInitialState = {
	loading: false,
	forumData: ''
}

const initialState = {
	...forumsInitialState,
	...forumInitialState
}

export const forumReducer = (state=initialState,action) => {

	switch(action.type){
		case ActionTypes.LIST_FORUMS_REQUEST:
			return {
				...state,
				isLoading: true,
			}
		case ActionTypes.LIST_FORUMS_SUCCESS:
			return {
				...state,
				isLoading: false,
				data: action.payload
			}
		case ActionTypes.FORUM_REQUEST:
			return {
				...state,
				loading: true,
			}
		case ActionTypes.FORUM_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
				forumData: action.payload
			}
		default: 
			return state;
	}
}