import { ActionTypes } from "../Constants/action-types";


const initialState = {
	isLoading: false,
	data: '	'
}

export const createThreadReducer = (state=initialState,action) => {

	switch(action.type){
		case ActionTypes.CREATE_THREAD_REQUEST:
			return {
				...state,
				isLoading: true,
			}
		case ActionTypes.CREATE_THREAD_SUCCESS:
			return {
				...state,
				isLoading: false,
				data: action.payload
			}
		default: 
			return state;
	}
}