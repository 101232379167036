import { lazy, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import AuthContextProvider from './Contexts/Auth';
import Routes from './Routes';
const Navbar = lazy(() => import('./Components/Navbar'));

const loading = (
  <div id="preloader">
    <div className="preloarder">
      <div className='SpanLoad'>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
)


function App(){
  return (
    <AuthContextProvider>
      <div className="App">
        <BrowserRouter>
          <Suspense fallback={loading}>
            <Navbar />
            <Routes />
          </Suspense>
        </BrowserRouter>
      </div>
      </AuthContextProvider>
  );
}

export default App;
