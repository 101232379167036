import React, { useContext } from 'react';
import { lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AuthContext } from './Contexts/Auth';
import GuardedRoute from './GuardedRoute';
const Home = lazy(() => import('./Components/Home'));
const NotFound = lazy(() => import('./Components/NotFound'));
const Login = lazy(() => import('./Components/Auth/Login'));
const Forum = lazy(() => import('./Components/Forums/Forums'));
const Threads = lazy(() => import('./Components/Forums/Threads'));

export default function Routes(props) {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/forum/:slug" component={Forum} />
        <Route exact path="/thread/:id" component={Threads} />
        <Route path="*" component={NotFound} />
    </Switch>
  );
}