import { ActionTypes } from "../Constants/action-types";


const initialState = {
	isLoading: false,
	status: ''
}

export const deleteForum = (state=initialState,action) => {

	switch(action.type){
		case ActionTypes.DELETE_FORUM_REQUEST:
			return {
				...state,
				isLoading: true,
			}
		case ActionTypes.DELETE_FORUM_SUCCESS:
			return {
				...state,
				isLoading: false,
				status: 'Success'
			}
		case ActionTypes.DELETE_FORUM_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.error
			}
		default: 
			return state;
	}
}