import { combineReducers } from 'redux';
import { authReducer } from './AuthReducer';
import { forumReducer } from './ForumReducer';
import { createForumReducer } from './CreateForumReducer';
import { deleteForum } from './DeleteForumReducer';

import { threadReducer } from './ThreadReducer';
import { postThreadReducer } from './PostThreadReducer';
import { createThreadReducer } from './CreateThreadReducer';

const reducers = combineReducers({
	authReducer,
	forumlists: forumReducer,
	createForum: createForumReducer,
	deleteForum,

	threadData: threadReducer,
	postThread: postThreadReducer,
	createThread: createThreadReducer,
})

export default reducers;