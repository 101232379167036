import { ActionTypes } from "../Constants/action-types";


const loginInitialState = {
  isLoading: false,
  isAuthenticated: false,
  token: null,
  error: null,
};

const initialState = {
  ...loginInitialState,
};

export const authReducer = (state=initialState,action) => {

	switch(action.type){
		case ActionTypes.LOGIN_REQUEST:
			return {
				...state,
				isLoading: true,
				isAuthenticated: false,
				error: null,
			}
		case ActionTypes.LOGIN_SUCCESS:
			return {
				...state,
				isLoading: false,
				isAuthenticated: true,
				token: action.token,
				error: null,
			}
		case ActionTypes.LOGIN_FAILURE:
	      return {
	        ...state,
	        isLoading: false,
					isAuthenticated: false,
	        error: action.error,
	      };
	  case ActionTypes.LOGOUT_REQUEST:
	      return {
	        ...state,
	      };
		default: 
			return state;
	}
}